<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <ActivityTypeForm
      v-if="!isLoading"
      :activityType="activityType"
      :imageSrc="imageSrc"
      :submitName="$t('edit')"
      v-on:addOrUpdateActivityType="addOrUpdateActivityType"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS, BASE_URL } from "@/utils/constants";
import ActivityType from "@/models/activityTypes/ActivityType";
import ActivityTypeForm from "@/components/activityTypes/ActivityTypeForm.vue";
import DEFAULT_IMG from "@/assets/images/activity-types.svg";

export default {
  name: "ActivityTypeEdit",
  components: {
    PreLoader,
    ExceptionWithImg,
    ActivityTypeForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  props: ["activityTypeToken"],
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      activityType: new ActivityType(),
      imageSrc: DEFAULT_IMG,
    };
  },
  methods: {
    async getActivityTypeDetails() {
      this.isLoading = true;
      this.activityType.activityTypeToken = this.activityTypeToken;

      try {
        const response = await this.activityType.getActivityTypeDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.activityType.fillData(response.data.activityTypes);
          this.imageSrc = BASE_URL + this.activityType.activityTypeImagePath;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "ActivityTypes" }).catch(() => {});
        }
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.$router.push({ name: "ActivityTypes" }).catch(() => {});
      }
      this.isLoading = false;
    },
    async addOrUpdateActivityType() {
      this.isLoading = true;
      try {
        const response = await this.activityType.addOrUpdateActivityType(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.activityType.setInitialValue();
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "ActivityTypes" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    showMsg(msg = "", isSuccess = false) {
      let variant = isSuccess ? "success" : "danger";
      this.$bvToast.toast(`${msg}`, {
        title: this.$t("alert"),
        variant: variant,
        toaster: "b-toaster-bottom-center",
        autoHideDelay: 5000,
      });
    },
  },
  async created() {
    this.getActivityTypeDetails();
  },
};
</script>

<style scoped lang="scss"></style>
